import React from 'react';
import PropTypes from 'prop-types';

import styles from './RadioGroup.module.scss';

function RadioGroup({
  groupName,
  entries,
  selected,
  setSelected,
  setFieldValue,
  fieldName,
  large,
  horizontalScroll,
  multiple,
  className,
}) {
  const handleChange = (input, name) => {
    if (multiple) {
      const isChecked = input?.target?.checked || false;

      if (isChecked) {
        setSelected((prev) => prev.concat([name]));
        setFieldValue(fieldName, selected.concat([name]));
      } else {
        setSelected((prev) => prev.filter((typ) => typ !== name));
        setFieldValue(fieldName, selected.filter((typ) => typ !== name));
      }
    } else {
      const value = input.target.value === selected ? null : input.target.value;
      setSelected(value);
      setFieldValue(fieldName, value);
    }
  };

  return (
    <div className={`${horizontalScroll ? styles.group_wrapper : ''} ${className || ''} flex a-center`}>
      {entries.map((entry, i) => (
        <div key={i} className={`${styles.choice} ${large ? styles.large : ''}`}>
          <input
            type="checkbox"
            id={`${groupName}_${entry.name}`}
            name={groupName}
            value={entry.name}
            checked={
              multiple
                ? selected.some((select) => select === entry.name)
                : selected === entry.name
            }
            onChange={(e) => handleChange(e, entry.name)}
          />
          <label htmlFor={`${groupName}_${entry.name}`}>{entry.label}</label>
        </div>
      ))}
    </div>
  );
}

RadioGroup.propTypes = {
  groupName: PropTypes.string.isRequired,
  entries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.number)]),
  setSelected: PropTypes.func,
  setFieldValue: PropTypes.func,
  fieldName: PropTypes.string,
  large: PropTypes.bool,
  horizontalScroll: PropTypes.bool,
  multiple: PropTypes.bool,
  className: PropTypes.string,
};

RadioGroup.defaultProps = {
  large: false,
  selected: '',
  setSelected: () => {},
  setFieldValue: () => {},
  fieldName: '',
  horizontalScroll: false,
  multiple: false,
  className: null,
};

export default RadioGroup;
