import React, { useState } from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';

// Utils
import { isArray } from 'lodash';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'next-i18next';
import cn from '../../../../src/utils/cn';

// Components
import WeeksPicker from '../../../molecules/WeeksPicker/WeeksPicker';
import ToggleCheck from '../../../molecules/ToggleCheck/ToggleCheck';
import Flexible from './Flexible/Flexible';
import FlashMessage from '../../../molecules/FlashMessage/FlashMessage';

import styles from './Weeks.module.scss';

const SwitchTabs = dynamic(() => (import('../../SwitchTabs/SwitchTabs')), { ssr: false });

function Weeks({
  offers, weeks, packages, formik, infoMessage, setIsFlexibleSearch,
}) {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const [tabsNeedUpdate, setTabsNeedUpdate] = useState(0);

  const [selectedDuration, setSelectedDuration] = useState(null);
  const [selectedMonths, setSelectedMonths] = useState([]);

  const handleResetDates = () => {
    formik?.setFieldValue('weeks', []);
    setSelectedDuration(null);
    setSelectedMonths([]);
  };

  const getResetButton = () => (
    <button
      type="button"
      className="underline text-c-secondary fw-700 c-pointer ws-nowrap"
      onClick={handleResetDates}
    >
      {t('search.reset')}
    </button>
  );

  return (
    <>
      <SwitchTabs
        id="switchTabsWeeks"
        needStyleUpdate={tabsNeedUpdate}
        tabs={[t('search.calendar'), t('search.flexible.iAmFlexible')]}
        className="mobile-m-r-20 mobile-m-l-20 mobile-m-t-15"
        tabsContent={[
          <div key="1">
            {infoMessage ? (
              <div className="m-b-25 flex mobile-p-r-20 mobile-p-l-20">
                <FlashMessage message={infoMessage} color="primary" />
              </div>
            ) : null}

            <div className="flex a-center j-spb">
              <ToggleCheck
                labels={[t('search.duration.week'), t('search.duration.weekend')]}
                name="slotType"
                onValueChange={(checked) => formik.setFieldValue('weekend', checked)}
                isChecked={formik.values.weekend}
                className="flex-auto mobile-m-r-25 mobile-m-l-25 ws-nowrap"
              />

              {!isMobile ? getResetButton() : null}
            </div>

            <WeeksPicker
              offers={offers}
              weeks={weeks}
              packages={packages}
              isWeekendMode={formik.values.weekend}
              selectedWeeks={isArray(formik.values.weeks) ? formik.values.weeks : [formik.values.weeks]}
              setSelectedWeeks={(value) => formik.setFieldValue('weeks', value)}
              setTabsNeedUpdate={setTabsNeedUpdate}
            />
          </div>,
          <div key="2">
            {infoMessage ? (
              <div className="m-b-25 flex mobile-p-r-20 mobile-p-l-20">
                <FlashMessage message={infoMessage} color="primary" />
              </div>
            ) : null}
            <Flexible
              offers={offers}
              packages={packages}
              selectedWeeks={formik.values.weeks}
              setSelectedWeeks={(value) => formik.setFieldValue('weeks', value)}
              setIsWeekendMode={(checked) => formik.setFieldValue('weekend', checked)}
              setIsFlexibleSearch={setIsFlexibleSearch}
              selectedDuration={selectedDuration}
              setSelectedDuration={setSelectedDuration}
              selectedMonths={selectedMonths}
              setSelectedMonths={setSelectedMonths}
            />
          </div>,
        ]}
      />

      {isMobile ? (
        <div className={cn([styles.mobile_reset, 'flex center'])}>
          {getResetButton()}
        </div>
      ) : null}
    </>
  );
}

Weeks.propTypes = {
  offers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  weeks: PropTypes.shape({}).isRequired,
  packages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  formik: PropTypes.shape({}).isRequired,
  infoMessage: PropTypes.string,
  setIsFlexibleSearch: PropTypes.func,
};

Weeks.defaultProps = {
  infoMessage: null,
  setIsFlexibleSearch: () => {},
};

export default Weeks;
