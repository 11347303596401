import { includes, remove } from 'lodash';

const addOrRemoveFromArray = (arr, value) => {
  const array = [...arr];
  if (!includes(array, value)) {
    array.push(value);
  } else {
    remove(array, (item) => item === value);
  }

  return array;
};

export default addOrRemoveFromArray;
