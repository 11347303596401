import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Utils
import { isNil } from 'lodash';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { getMonth, addDays } from 'date-fns';
import addOrRemoveFromArray from '../../../../../src/utils/addOrRemoveFromArray';
import useWeekId from '../../../../../src/hooks/useWeekId';
import useLocalizedDate from '../../../../../src/hooks/useLocalizedDate';

// Components
import RadioGroup from '../../../../molecules/RadioGroup/RadioGroup';
import InputCard from '../../../../molecules/InputCard/InputCard';

import styles from './Flexible.module.scss';

function Flexible({
  offers,
  packages,
  setSelectedWeeks,
  setIsWeekendMode,
  setIsFlexibleSearch,
  selectedDuration,
  setSelectedDuration,
  selectedMonths,
  setSelectedMonths,
}) {
  const { t } = useTranslation();
  const { locale } = useRouter();

  const { getDateFromWeekId } = useWeekId();
  const { dateFnsLocaleByLang } = useLocalizedDate();

  const [allMonths, setAllMonths] = useState([]);

  // durationId :
  // - 1 => week
  // - 2 => weekend
  const durations = [
    { name: 'weekend', durationId: 2, label: t('search.flexible.weekend') },
    { name: 'week', durationId: 1, label: t('search.flexible.week') },
  ];

  useEffect(() => {
    const months = [];
    offers.forEach(
      (offer) => {
        const offerMonth = getMonth(getDateFromWeekId(offer.weekId));
        if (!months.some((month) => month.id === offerMonth)) {
          months.push({
            id: offerMonth,
            label: dateFnsLocaleByLang[locale].localize.month(offerMonth),
          });
        }
      },
    );
    if (months?.length) setAllMonths(months);
  }, [offers]);

  const isWeekendSelected = (selection) => selection === 'weekend';

  const getMatchingWeeks = (duration, months) => {
    const matchingWeeks = [];

    offers.forEach((offer) => {
      // if weekend selected, need to check saturday instead of monday
      const monthOffer = isWeekendSelected(duration)
        ? getMonth(addDays(getDateFromWeekId(offer.weekId), 5))
        : getMonth(getDateFromWeekId(offer.weekId));

      if (months.includes(monthOffer)) {
        const pack = packages.find((p) => p.id === offer.packageId);
        const packIsWeekend = pack.durationId === 2;

        if (
          (isWeekendSelected(duration) && packIsWeekend)
          || (!isWeekendSelected(duration) && !packIsWeekend)
        ) {
          if (!matchingWeeks.includes(offer.weekId)) {
            matchingWeeks.push(offer.weekId);
          }
        }
      }
    });

    return matchingWeeks;
  };

  useEffect(() => {
    if (selectedDuration && selectedMonths) {
      if (selectedDuration && selectedMonths.length) {
        const selection = getMatchingWeeks(selectedDuration, selectedMonths);
        setSelectedWeeks(selection.length ? selection : []);
      } else {
        setSelectedWeeks([]);
      }

      if (selectedDuration) setIsWeekendMode(isWeekendSelected(selectedDuration));

      setIsFlexibleSearch(selectedMonths?.length > 0 && !isNil(selectedDuration));
    }
  }, [selectedDuration, selectedMonths]);

  const handleMonthSelectionChange = (checkboxId) => {
    const updated = addOrRemoveFromArray(selectedMonths, checkboxId);
    setSelectedMonths(updated);
  };

  return (
    <div className={styles.flexible}>
      <div className="m-b-10">
        <p className="m-b-20 text-c-primary fw-500 fs-14">{t('search.flexible.wichFormat')}</p>
        <RadioGroup
          groupName="duration"
          entries={durations}
          selected={selectedDuration || null}
          setSelected={setSelectedDuration}
          horizontalScroll
        />
      </div>

      <div className="m-b-10">
        <p className="m-b-20 text-c-primary fw-500 fs-14">{t('search.flexible.when')}</p>
        {allMonths?.length ? (
          <div className={`${styles.slider} flex a-center`}>
            {allMonths.map((month, i) => (
              <InputCard
                key={i}
                id={`month_${month.id}`}
                name={`month_${month.id}`}
                onChange={() => handleMonthSelectionChange(month.id)}
                checked={selectedMonths?.includes(month.id)}
                label={month.label}
                size="auto"
              />
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
}

Flexible.propTypes = {
  offers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  packages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setSelectedWeeks: PropTypes.func,
  setIsWeekendMode: PropTypes.func,
  setIsFlexibleSearch: PropTypes.func,
  selectedMonths: PropTypes.arrayOf(PropTypes.number),
  selectedDuration: PropTypes.string,
};

Flexible.defaultProps = {
  setSelectedWeeks: () => {},
  setIsWeekendMode: () => {},
  setIsFlexibleSearch: () => {},
  selectedMonths: [],
  selectedDuration: null,
};

export default Flexible;
