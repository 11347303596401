const getSeasonColor = (seasonId) => {
  switch (seasonId) {
    case 1: // Basse
      return 'green';
    case 2: // Moyenne
      return 'orange';
    case 3: // Haute
      return 'red';
    case 4: // Très basse
      return 'green';
    case 5: // Très haute
      return 'red';
    default:
      return null;
  }
};

export default getSeasonColor;
