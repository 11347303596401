import React from 'react';
import PropTypes from 'prop-types';
import cn from '../../../src/utils/cn';

import styles from './InputCard.module.scss';

function InputCard({
  id, disabled, name, checked, onChange, isRadio, label, size,
}) {
  return (
    <div className={styles.card_wrapper}>
      <label
        htmlFor={id}
        className={cn([
          'flex center mobile-j-spb',
          styles.card,
          checked ? styles.selected : '',
          disabled ? styles.disabled : '',
          size ? styles[size] : '',
        ])}
      >
        <span className={styles.card_label}>{label}</span>

        <input
          id={id}
          type="checkbox"
          name={name}
          onChange={onChange}
          checked={checked}
          disabled={disabled}
        />
        <span
          className={`${styles.card_box} ${isRadio ? styles.is_radio : ''}`}
        />
      </label>
    </div>
  );
}

InputCard.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isRadio: PropTypes.bool,
  size: PropTypes.string,
};

InputCard.defaultProps = {
  disabled: false,
  isRadio: false,
  size: null,
};

export default InputCard;
